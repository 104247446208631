import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'modules/dashboard/constants';
import styles from 'modules/dashboard/components/tab-container/workbook-report/workbook-report.module.scss';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { selectEnableHotelGroups, selectHotelName } from 'modules/dashboard/selectors';
import { getVisualName } from 'modules/dashboard/functions';
import {
  selectD1SetIsDisplay,
  selectD2SetIsDisplay,
  selectD3SetIsDisplay,
  selectEnableSecondaryHotelGroups,
  selectHotelFocusOn,
  selectWorkbookFilterList,
  selectWorkbookHotelComparison,
  selectWorkbookHotelFilterList,
  selectWorkbookHotelVisual,
} from '../../../selectors';
import useWorkbookWidget from '../../../hooks/use-workbook-widget';
import { workbookActions } from '../../../slice';
import VisualGrid from '../../../../visual-grid';
import WorkBookFilter from '../../filter-panel';

/**
 * Workbook widget report with filters
 * @param {Number} value - Workbook widget tab value
 * @param {String} accessToken - Power BI access token
 * @param {Object} visuals - Workbook tab visual details
 * @param {Boolean} active - whether Workbook tab is active
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */

const TestWorkbookReport = ({
  value,
  accessToken,
  visuals,
  active,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  const dispatch = useDispatch();
  //
  useWorkbookWidget();
  //
  const selectedHotel = useSelector(selectHotelName);
  const hotelVisuals = useSelector(selectWorkbookHotelVisual);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isWorkbookHotelComparison = useSelector(selectWorkbookHotelComparison);
  // States are obtained from redux store. Refer each selector function for details.
  const workbookFilterList = useSelector(selectWorkbookFilterList);
  const workbookHotelFilterList = useSelector(selectWorkbookHotelFilterList);
  const d1SetIsDisplay = useSelector(selectD1SetIsDisplay);
  const d2SetIsDisplay = useSelector(selectD2SetIsDisplay);
  const d3SetIsDisplay = useSelector(selectD3SetIsDisplay);

  // Local states to set Workbook visual loading states
  const [isD1Loaded, setIsD1Loaded] = useState(false);
  const [isD2Loaded, setIsD2Loaded] = useState(false);
  const [isD3Loaded, setIsD3Loaded] = useState(false);
  // To get visuals based on the configuration
  useEffect(() => {
    // To enable hotel comparison view based on selected hotels
    if (hotelFocusOn?.id !== selectedHotel?.id) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else if (enableSecondaryHotelGroups && !enableHotelGroups) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else if (!enableSecondaryHotelGroups && enableHotelGroups) {
      dispatch(workbookActions.setIsWorkbookHotelComparison(true));
    } else {
      dispatch(workbookActions.setIsWorkbookHotelComparison(false));
    }
  }, [enableHotelGroups, enableSecondaryHotelGroups, selectedHotel, hotelFocusOn]);
  // To get visual details based on index
  const getVisual = (index) => {
    const found = isWorkbookHotelComparison
      ? hotelVisuals?.find((visual) => visual?.order === index)
      : visuals?.find((visual) => visual?.order === index);
    return found;
  };
  const getWidget = (index, cssClassName, setIsLoaded = () => {}, filter = workbookFilterList) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  //
  return (
    active && (
      <Grid
        container
        direction="row"
        className={styles.mainGrid}
        sx={{
          display: value === TAB_VALUE.TEST_WORKBOOK_REPORT ? '' : 'none',
        }}
      >
        <Grid
          item
          xs={2}
          cc={2.5}
          md={2}
          sx={{
            ml: {
              xs: 0,
              cc: 0.25,
              md: 0,
            },
          }}
        >
          <WorkBookFilter
            reportType={reportType}
            visual={getWidget(100, styles.symmetricComparisonWindow)}
            visualName={getVisualName(visuals, 100)}
            latestDate={latestDate}
            hotelTimezone={hotelTimezone}
            isLoaded={isD1Loaded && isD2Loaded && isD3Loaded}
            toggleDisabled={toggleDisabled}
          />
        </Grid>
        <Grid
          container
          item
          xs={10}
          cc={9.25}
          md={10}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {isWorkbookHotelComparison ? (
            <Box sx={{ flexGrow: 1 }}>
              <VisualGrid
                isDisplay={d1SetIsDisplay}
                order={10}
                visualsList={hotelVisuals}
                visual={getWidget(
                  10,
                  styles.workbookReportD1,
                  setIsD1Loaded,
                  workbookHotelFilterList
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
                displayHotelName
              />
              <VisualGrid
                isDisplay={d3SetIsDisplay}
                order={12}
                visualsList={hotelVisuals}
                visual={getWidget(
                  12,
                  styles.workbookReportD3,

                  setIsD3Loaded,

                  workbookHotelFilterList
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD3SetIsDisplay(!d3SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
              />
              <VisualGrid
                isDisplay={d2SetIsDisplay}
                order={11}
                visualsList={hotelVisuals}
                visual={getWidget(
                  11,
                  styles.workbookReportD2,

                  setIsD2Loaded,

                  workbookHotelFilterList
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD2SetIsDisplay(!d2SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
              />
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <VisualGrid
                isDisplay={d1SetIsDisplay}
                order={10}
                visualsList={visuals}
                visual={getWidget(
                  10,
                  styles.workbookReportD1,

                  setIsD1Loaded
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
                displayHotelName
              />
              <VisualGrid
                isDisplay={d3SetIsDisplay}
                order={12}
                visualsList={visuals}
                visual={getWidget(
                  12,
                  styles.workbookReportD3,

                  setIsD3Loaded
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD3SetIsDisplay(!d3SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
              />
              <VisualGrid
                isDisplay={d2SetIsDisplay}
                order={11}
                visualsList={visuals}
                visual={getWidget(
                  11,
                  styles.workbookReportD2,

                  setIsD2Loaded
                )}
                style={styles.workbookVisualGrid}
                onClick={() => {
                  dispatch(workbookActions.setD2SetIsDisplay(!d2SetIsDisplay));
                }}
                isWorkbookHotelComparison={isWorkbookHotelComparison}
                comparisonHotel={hotelFocusOn}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    )
  );
};
//
export default TestWorkbookReport;
