import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from './slice';
//
const selectDomain = (state) => state[SLICE.WORKBOOK_REPORT] || initialState;

/**
 * Getting summary widget booking date state to summary widget component
 */
export const selectWorkbookBookingDate = createSelector(
  [selectDomain],
  (state) => state.workbookBookingDate
);

/**
 * Getting date comparison state to summary widget component
 */
export const selectDateComparison = createSelector([selectDomain], (state) => state.dateComparison);

/**
 * Getting summary report filter list state to summary widget component
 */
export const selectWorkbookFilterList = createSelector(
  [selectDomain],
  (state) => state.workbookFilterList
);

/**
 * Getting summary report filter list state to summary widget component
 */
export const selectWorkbookHotelFilterList = createSelector(
  [selectDomain],
  (state) => state.workbookHotelFilterList
);
/**
 * Getting alignment state to summary widget component
 */
export const selectAlignment = createSelector([selectDomain], (state) => state.alignment);
/**
 *  Getting summary widget alignment toggle state to summary widget component
 */
export const selectWorkbookAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.workbookAlignmentToggle
);
/**
 * Getting summary otb state for summary widget component
 */
export const selectWorkbookOtb = createSelector([selectDomain], (state) => state?.workbookOtb);

/**
 *Getting is summary custom date state for summary widget component
 */
export const selectWorkbookCustomDate = createSelector(
  [selectDomain],
  (state) => state?.workbookCustomDate
);

/**
 *Getting is summary start date state for summary widget component for custom date comparison
 */
export const selectWorkbookStartDate = createSelector(
  [selectDomain],
  (state) => state?.workbookStartDate
);

/**
 *Getting is summary end date state for summary widget component for custom date comparison
 */
export const selectWorkbookEndDate = createSelector(
  [selectDomain],
  (state) => state?.workbookEndDate
);
/**
 * Getting symmetric comparison toggle state for summary widget component
 */
export const selectSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.symmetricComparisonToggle
);
/**
 * Getting workbook selected date for workbook widget component
 */
export const selectWorkbookDateSelected = createSelector(
  [selectDomain],
  (state) => state?.workbookDateSelected
);
/**
 * Getting pdf exported object into the component
 */
export const selectPdfExportedObject = createSelector([selectDomain], (state) => state?.exportData);
/**
 * Getting loading state into the component
 */
export const selectLoader = createSelector([selectDomain], (state) => state?.loading);
/**
 *  Getting generated workbook tab url
 */
export const selectWorkbookUrl = createSelector([selectDomain], (state) => state.workbookUrl);
/**
 *  Getting symmetric comparison visual display state to the component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
/**
 *  Getting D1 display state to the component
 */
export const selectD1SetIsDisplay = createSelector([selectDomain], (state) => state.d1SetIsDisplay);
/**
 *  Getting D2 display state to the component
 */
export const selectD2SetIsDisplay = createSelector([selectDomain], (state) => state.d2SetIsDisplay);
/**
 *  Getting D3 display state to the component
 */
export const selectD3SetIsDisplay = createSelector([selectDomain], (state) => state.d3SetIsDisplay);
/**
 *  Getting selected enable secondary hotel state to the component
 */
export const selectEnableSecondaryHotelGroups = createSelector(
  [selectDomain],
  (state) => state.enableSecondaryHotelGroups
);
/**
 *  Getting hotel view focus on state to dashboard component
 */
export const selectHotelFocusOn = createSelector([selectDomain], (state) => state.hotelFocusOn);
/**
 *Getting normalized view state for the component
 */
export const selectNormalizedView = createSelector(
  [selectDomain],
  (state) => state?.normalizedView
);
/**
 *Getting workbook hotel comparison visual state for the component
 */
export const selectWorkbookHotelVisual = createSelector(
  [selectDomain],
  (state) => state?.workbookHotelVisuals
);
/**
 *Getting is workbook hotel comparison state for the component
 */
export const selectWorkbookHotelComparison = createSelector(
  [selectDomain],
  (state) => state?.isWorkbookHotelComparison
);
