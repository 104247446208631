import { Grid, Dialog, DialogTitle, DialogContent, Box, Button, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import CustomToggleSwitch from 'modules/common/components/toggle-switch';
import { useEffect, useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import SaveFilter from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/save-filter';
import LoadFilter from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/load-filter';
import AggregationTransferList from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/aggregation-transfer-list';
import QueryBox from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/query-box';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { X } from 'react-feather';
import { selectShowCustomGroupingFilterName } from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
/**
 * Custom aggregation filter component is implemented
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @param {Array} focusOnList - List of focus on dropdown values
 * @param {String} breakdown - Selected breakdown
 * @returns
 */
const CustomAggregation = ({ open, onClose, focusOnList, breakdown }) => {
  const dispatch = useDispatch();
  const showFilterName = useSelector(selectShowCustomGroupingFilterName);
  //
  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]);
  const [showLoadFilter, setShowLoadFilter] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  // Triggered once when the component mounted
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  // Triggered when the selected breakdown changes
  useEffect(() => {
    // Update global and local states of custom group on breakdown change
    setGroup1([]);
    setGroup2([]);
    dispatch(trendActions.setSegmentSetAQuery(''));
    dispatch(trendActions.setSegmentSetBQuery(''));
    dispatch(trendActions.setSelectedFilterName(''));
    dispatch(trendActions.setSegmentSetAName(''));
    dispatch(trendActions.setSegmentSetBName(''));
  }, [breakdown]);
  //
  useEffect(() => {
    // Update global and local states of custom group on show filter name change
    if (!showFilterName) {
      setGroup1([]);
      setGroup2([]);
      dispatch(trendActions.setSegmentSetAQuery(''));
      dispatch(trendActions.setSegmentSetBQuery(''));
      dispatch(trendActions.setSelectedFilterName(''));
      dispatch(trendActions.setSegmentSetAName(''));
      dispatch(trendActions.setSegmentSetBName(''));
      dispatch(trendActions.setSegmentGroup1([]));
      dispatch(trendActions.setSegmentGroup2([]));
    }
  }, [showFilterName]);
  //
  const modalAction = (reason, action) => {
    if (reason && reason === 'backdropClick') return;
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => modalAction(reason, false)}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: 1 }}>Custom Grouping</DialogTitle>
      <IconButton
        size="small"
        aria-label="close"
        onClick={() => modalAction(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent>
        <Grid container direction="row" justifyContent="flex-end">
          <Button
            variant="Text"
            startIcon={<FolderIcon />}
            onClick={() => setShowLoadFilter(true)}
            sx={{ color: 'grey', paddingRight: 0, paddingTop: 0, paddingLeft: 0 }}
          >
            Load Saved Grouping
          </Button>
        </Grid>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 4,
          }}
        >
          <AggregationTransferList
            focusOnList={focusOnList}
            group1={group1}
            group2={group2}
            setGroup1={(value) => setGroup1(value)}
            setGroup2={(value) => setGroup2(value)}
            isQueryEnabled={isQueryEnabled}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 2,
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <CustomToggleSwitch
            label="Advanced Querying"
            title=""
            onChange={(event) => setIsQueryEnabled(event.target.checked)}
            checked={isQueryEnabled}
          />

          <QueryBox
            breakdownSubCategories={focusOnList}
            setGroup1={setGroup1}
            setGroup2={setGroup2}
            onClose={() => {
              modalAction(false);
            }}
            open={open}
            isQueryEnabled={isQueryEnabled}
            loadFilter={loadFilter}
            setLoadFilter={setLoadFilter}
          />
        </Box>
        <SaveFilter
          breakdown={breakdown}
          group1={group1}
          group2={group2}
          setGroup1={setGroup1}
          setGroup2={setGroup2}
          onClose={() => {
            modalAction(false);
          }}
        />
        <LoadFilter
          openLoad={showLoadFilter}
          onClose={() => setShowLoadFilter(false)}
          setGroup1={setGroup1}
          setGroup2={setGroup2}
          setLoadFilter={setLoadFilter}
          breakdown={breakdown}
        />
      </DialogContent>
    </Dialog>
  );
};
//
export default CustomAggregation;
