import { ENVIRONMENT } from 'config';
import { dashboardActions } from 'modules/dashboard/slice';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Tooltip, Popover, ListItemText, ListItem } from '@mui/material';
import { selectNotifications } from 'modules/dashboard/selectors';
import { useRef, useState } from 'react';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { History } from '@mui/icons-material';
import { Indicator, ScrollList } from '../../style';
/**
 * Notification component define here
 * @returns
 */
const NotificationWindow = () => {
  const dispatch = useDispatch();
  //
  const notificationsList = useSelector(selectNotifications);
  const organizationId = useSelector(selectOrganizationId);
  //
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  //
  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          onClick={() => setOpen(true)}
          ref={ref}
          size="large"
          sx={{ mr: 2 }}
          disableRipple
        >
          <Indicator
            badgeContent={
              notificationsList?.filter((notificationItem) => !notificationItem.isOpen).length
            }
          >
            <History sx={{ fontSize: 20, color: 'gray' }} />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setOpen(false)}
        open={isOpen}
      >
        <ScrollList disablePadding>
          {notificationsList?.map((notificationItem) => (
            <ListItem
              divider
              component="a"
              href={`${ENVIRONMENT.AZURE_CDN_URL}/${notificationItem?.payload.fileName}`}
              target="_blank"
              key={1}
              sx={{
                backgroundColor: notificationItem?.isOpen ? 'white' : '#d3edeb',
              }}
              onClick={() =>
                dispatch(
                  dashboardActions.updateNotification({
                    organizationId,
                    userId: notificationItem?.userId,
                    notificationId: notificationItem.id,
                    isOpen: true,
                  })
                )
              }
            >
              <ListItemText
                primary={notificationItem?.subject}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                  color: 'textPrimary',
                }}
              />
            </ListItem>
          ))}
        </ScrollList>
      </Popover>
    </>
  );
};
//
export default NotificationWindow;
