import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import ActionButton from 'modules/common/components/action-button';
import { selectIsUserConfigurationNotExist } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { notificationActions } from 'modules/common/notifications/slice';
import { Modal, SplitButton } from 'modules/common/components';
import ERROR_TYPES from 'modules/common/constants/error-types';
import {
  Alert,
  TextField,
} from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/style';
import addFilterFormValidation from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/validation/add-filter-form-validation';
import {
  selectExistedFilter,
  selectSegmentSetAName,
  selectSegmentSetAQuery,
  selectSegmentSetBName,
  selectSegmentSetBQuery,
  selectSelectedFilterName,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';

/**
 * used to inject formik context in-order to control form field from outside of formik context
 * @param {String} selectedFilterName - Name of the loaded filter
 * @param {String} selectedSetAName - Group A name of selected filter
 * @param {String} selectedSetBName - Group B name of selected filter
 * @constructor
 */
const FormikContextInjector = ({ selectedFilterName, selectedSetAName, selectedSetBName }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  useEffect(() => {
    if (selectedFilterName) {
      setFieldValue('filterName', selectedFilterName);
      setFieldTouched('filterName', false);
    } else {
      setFieldValue('filterName', '');
      setFieldTouched('filterName', false);
    }
  }, [selectedFilterName]);
  useEffect(() => {
    if (selectedSetAName) {
      setFieldValue('setAName', selectedSetAName);
      setFieldTouched('setAName', false);
    } else {
      setFieldValue('setAName', '');
      setFieldTouched('setAName', false);
    }
  }, [selectedSetAName]);
  useEffect(() => {
    if (selectedSetBName) {
      setFieldValue('setBName', selectedSetBName);
      setFieldTouched('setBName', false);
    } else {
      setFieldValue('setBName', '');
      setFieldTouched('setBName', false);
    }
  }, [selectedSetBName]);
};

/** Implementation to save custom aggregation combination
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const SaveFilterDialog = ({ breakdown, group1, group2, onClose, setGroup1, setGroup2 }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const segmentSetAQuery = useSelector(selectSegmentSetAQuery);
  const segmentSetBQuery = useSelector(selectSegmentSetBQuery);
  const selectedFilterName = useSelector(selectSelectedFilterName);
  const selectedSetAName = useSelector(selectSegmentSetAName);
  const selectedSetBName = useSelector(selectSegmentSetBName);
  const existedFilter = useSelector(selectExistedFilter);
  const isUserConfigurationNotExist = useSelector(selectIsUserConfigurationNotExist);
  //
  const [openOverwriteModal, setOpenOverwriteModal] = useState(false);
  const [saveValueObj, setSaveValueObj] = useState(null);
  const [optionIndex, setOptionIndex] = useState('submit');
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  // Update global state of custom group to trigger Power BI filter
  const applyFilter = () => {
    dispatch(trendActions.setSegmentGroup1(group1));
    dispatch(trendActions.setSegmentGroup2(group2));
    dispatch(trendActions.setShowCustomGroupingFilterName(!isEmpty(group2) || !isEmpty(group1)));
  };
  // Options to display in button dropdown
  const buttonOptions = ['Save & Apply', 'Save', 'Apply'];
  // create save filter object and dispatch validate filter name
  const createFilterAndValidateFilterName = async (values) => {
    if (buttonOptions[optionIndex] === 'Apply') {
      applyFilter();
      onClose();
    } else {
      dispatch(trendActions.resetFilterNameValidationStatus());
      const valueObj = {
        userId: currentUser?.id,
        organizationId,
        queryPersist: [
          {
            filterName: values.filterName.trim(),
            breakdown,
            query: {
              ...(segmentSetAQuery && {
                setA: {
                  query: segmentSetAQuery,
                  ...(values.setAName && { name: values.setAName }),
                },
              }),
              ...(segmentSetBQuery && {
                setB: {
                  query: segmentSetBQuery,
                  ...(values.setBName && { name: values.setBName }),
                },
              }),
            },
          },
        ],
      };
      setSaveValueObj(valueObj);
      //
      const query = `breakdown=${breakdown}&filterName=${values.filterName.trim()}`;
      dispatch(
        trendActions.getFilterNameValidation({
          organizationId,
          userId: currentUser?.id,
          query,
        })
      );
    }
  };
  // dispatch save filter , reset save object, filter validation state and close update warning modal
  const saveSubmit = (filterObject) => {
    dispatch(trendActions.saveSegmentFilter(filterObject));
    setSaveValueObj(null);
    dispatch(trendActions.resetFilterNameValidationStatus());
    dispatch(dashboardActions.resetUserConfigurationStatus());
    setOpenOverwriteModal(false);
    if (buttonOptions[optionIndex] === 'Save & Apply') {
      applyFilter();
      onClose();
    }
  };
  // checks filter name already exists and open update warning modal
  useEffect(() => {
    if (saveValueObj !== null && existedFilter !== null && !isUserConfigurationNotExist) {
      if (existedFilter) {
        setOpenOverwriteModal(true);
      } else {
        saveSubmit(saveValueObj);
      }
    }
    // if user configuration is not exist, save the query persist
    if (isUserConfigurationNotExist && saveValueObj !== null && existedFilter === null) {
      saveSubmit(saveValueObj);
    }
  }, [saveValueObj, existedFilter, isUserConfigurationNotExist]);
  //
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  return (
    <Formik
      initialValues={{
        filterName: selectedFilterName ?? '',
        setAName: selectedSetAName ?? '',
        setBName: selectedSetBName ?? '',
      }}
      validationSchema={buttonOptions[optionIndex] !== 'Apply' ? addFilterFormValidation : ''}
      onSubmit={(values) => createFilterAndValidateFilterName(values)}
    >
      {({ errors, handleBlur, setFieldValue, handleSubmit, values, touched, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item paddingRight={1} xs={2.83}>
              <TextField
                name="setAName"
                value={values.setAName}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.setAName && errors.setAName)}
                fullWidth
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('setAName', event.target.value);
                  dispatch(trendActions.setSegmentSetAName(event.target.value));
                }}
                label="Group A Name"
                size="small"
              />
              <span>
                <Typography
                  sx={{ whiteSpace: 'pre-line', fontSize: 11, color: 'red', minHeight: 15 }}
                >
                  {(touched.setAName && errors.setAName) ?? ' '}
                </Typography>
              </span>
            </Grid>
            <Grid item paddingRight={1} xs={2.83}>
              <TextField
                name="setBName"
                value={values.setBName}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.setBName && errors.setBName)}
                fullWidth
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('setBName', event.target.value);
                  dispatch(trendActions.setSegmentSetBName(event.target.value));
                }}
                label="Group B Name"
                size="small"
              />
              <span>
                <Typography
                  sx={{ whiteSpace: 'pre-line', fontSize: 11, color: 'red', minHeight: 15 }}
                >
                  {(touched.setBName && errors.setBName) ?? ' '}
                </Typography>
              </span>
            </Grid>
            <Grid item paddingRight={1} xs={2.83}>
              <TextField
                name="filterName"
                value={values.filterName}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.filterName && errors.filterName)}
                fullWidth
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('filterName', event.target.value);
                  dispatch(trendActions.setSelectedFilterName(event.target.value));
                }}
                label="Filter Name"
                size="small"
              />
              <span>
                <Typography
                  sx={{ whiteSpace: 'pre-line', fontSize: 11, color: 'red', minHeight: 15 }}
                >
                  {(touched.filterName && errors.filterName) ?? ' '}
                </Typography>
              </span>
            </Grid>
            <Grid item paddingRight={1}>
              <ActionButton
                label="Cancel"
                onClick={() => {
                  setGroup1([]);
                  setGroup2([]);
                  dispatch(trendActions.setSegmentSetAQuery(''));
                  dispatch(trendActions.setSegmentSetBQuery(''));
                  dispatch(trendActions.setSelectedFilterName(''));
                  dispatch(trendActions.setShowCustomGroupingFilterName(false));
                  dispatch(trendActions.setSegmentSetAName(''));
                  dispatch(trendActions.setSegmentSetBName(''));
                  onClose();
                }}
                sx={{ maxWidth: 50 }}
              />
            </Grid>
            <Grid item>
              <SplitButton
                options={buttonOptions}
                color="success"
                disabled={isSubmitting || (isEmpty(group1) && isEmpty(group2))}
                handleClick={(index) => setOptionIndex(index)}
                tabName="trend"
              />
            </Grid>
            <FormikContextInjector
              selectedFilterName={selectedFilterName}
              selectedSetAName={selectedSetAName}
              selectedSetBName={selectedSetBName}
            />
          </Grid>
          <Grid>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert severity={notification?.type}>{notification?.message}</Alert>
            )}
          </Grid>
          <Modal
            open={openOverwriteModal}
            handleClose={() => {
              dispatch(trendActions.resetFilterNameValidationStatus());
              setOpenOverwriteModal(false);
            }}
            title="Update Filter"
            content="Filter Name already exists. Are you sure you want to overwrite the filter?"
            handleSuccess={() => saveSubmit(saveValueObj)}
            closeLabel="Cancel"
            successLabel="Update"
            variant="contained"
            color="error"
          />
        </form>
      )}
    </Formik>
  );
};
//
export default SaveFilterDialog;
