import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import { Search } from 'react-feather';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { isEmpty } from 'modules/common/helpers/object';
import { notificationActions } from 'modules/common/notifications/slice';
import Row from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/components/load-filter/row';
import { Div } from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/style';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  selectSavedAggregationFilters,
  selectTrendSegmentChartBreakdown,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';

/** Implementation to load saved custom aggregation combination
 * @param {Boolean} openLoad - To open/ close dialog box
 * @param {Function} listFilters  - Function to get new filter list for the selected page
 * @param {Function} setLoadFilter - Function to set load filter true when the saved filter is clicked to be loaded
 * @param {Array} breakdown - Selected breakdown
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const LoadFilter = ({ openLoad, onClose, setLoadFilter, breakdown }) => {
  const dispatch = useDispatch();
  // Notification, filters, organization id and current user values are selected from store here. Refer each selector function for details
  const notification = useSelector(selectNotification);
  const filters = useSelector(selectSavedAggregationFilters);
  const organizationId = useSelector(selectOrganizationId);
  const currentUser = useSelector(selectAuthUser);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);

  // Local state to control pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParam, setSearchTerm] = useState('');
  //
  const modalAction = () => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    setSearchTerm('');
    onClose();
  };
  // Get paginated saved filter list for custom grouping load filter
  const listFilters = (page) => {
    const query = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&breakdown=${chartBreakdown}&page=${page}&filterName=${searchParam}&searchEnabled=true`;
    dispatch(trendActions.getAggregationFilter({ organizationId, userId: currentUser?.id, query }));
  };
  //
  useEffect(() => {
    setCurrentPage(0, listFilters(0));
  }, [searchParam]);
  // To delete saved filter
  const deleteFilters = (filterId) => {
    const query = `breakdown=${breakdown}&filterName=${filterId}`;
    dispatch(
      trendActions.deleteAggregationFilter({
        organizationId,
        userId: currentUser?.id,
        query,
      })
    );
  };
  //
  return (
    <Dialog open={openLoad} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Filters</DialogTitle>
      <DialogContent>
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          m={1}
          mb={2}
        >
          <TextField
            placeholder="Search"
            fullWidth
            size="small"
            value={searchParam}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search size={16} />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Filter Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(filters?.totalData) && filters?.totalData?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Div>
                      No saved filters.
                      <br />
                      <span>Please save filters before selecting</span>
                    </Div>
                  </TableCell>
                </TableRow>
              ) : (
                !isEmpty(filters?.totalData) &&
                filters?.totalData?.map((filter) => (
                  <Row
                    key={filter?.queryPersist?.filterName}
                    filter={filter?.queryPersist}
                    onClose={onClose}
                    setLoadFilter={setLoadFilter}
                    deleteFilters={deleteFilters}
                  />
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  count={filters?.totalCount?.[0]?.totalCount ?? 0}
                  rowsPerPage={5}
                  page={currentPage}
                  onPageChange={(event, newPage) => {
                    setCurrentPage(newPage, listFilters(newPage));
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            color="primary"
            onClick={() => modalAction()}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
//
export default LoadFilter;
