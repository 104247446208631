import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AddToPhotos, DeleteOutline } from '@mui/icons-material';
import { useState } from 'react';
import { Modal } from 'modules/common/components';
import { useDispatch } from 'react-redux';
import { LABELS } from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/constants';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
/**
 * Saved filters detailed view
 * @param {Array} filter - List of saved Group A, Group B filters (queries)
 * @param {Function} onClose - Function to trigger on close event
 * @param {Function} deleteFilter - Function to delete saved filter
 * @param {Function} setLoadFilter - Function to set load filter true when the saved filter is clicked to be loaded
 * @returns
 */

const Row = ({ filter, deleteFilters, onClose, setLoadFilter }) => {
  const dispatch = useDispatch();
  //
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon sx={{ height: 22, width: 22 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ height: 22, width: 22 }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {filter?.filterName}
        </TableCell>
        <TableCell align="center">
          {/* Update local and global state to set filter values on filter load */}
          <IconButton
            onClick={() => {
              setLoadFilter(true);
              dispatch(trendActions.setSegmentSetAQuery(filter?.query?.setA?.query));
              dispatch(trendActions.setSegmentSetBQuery(filter?.query?.setB?.query));
              dispatch(trendActions.setSelectedFilterName(filter?.filterName));
              dispatch(
                trendActions.setSegmentSetAName(
                  filter?.query?.setA?.name ? filter?.query?.setA?.name : ''
                )
              );
              dispatch(
                trendActions.setSegmentSetBName(
                  filter?.query?.setB?.name ? filter?.query?.setB?.name : ''
                )
              );
              onClose();
            }}
            size="medium"
          >
            <AddToPhotos sx={{ height: 18, width: 18 }} />
          </IconButton>
          <IconButton onClick={() => setOpenDeleteModal(true)} size="medium">
            <DeleteOutline sx={{ height: 18, width: 18 }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                Criteria
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Field Label</TableCell>
                    <TableCell>Selected Criteria</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={filter?.query?.setA?.query}>
                    <TableCell component="th" scope="row">
                      {filter?.query?.setA?.name ? filter?.query?.setA?.name : LABELS.GROUP_A}
                    </TableCell>
                    <TableCell>
                      {filter?.query?.setA?.query && (
                        <Chip
                          label={
                            <Typography style={{ whiteSpace: 'normal' }}>
                              {filter?.query?.setA?.query}
                            </Typography>
                          }
                          sx={{ marginRight: 2, height: '100%' }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow key={filter?.query?.setB?.query}>
                    <TableCell component="th" scope="row">
                      {filter?.query?.setB?.name ? filter?.query?.setB?.name : LABELS.GROUP_B}
                    </TableCell>
                    <TableCell>
                      {filter?.query?.setB?.query && (
                        <Chip
                          label={
                            <Typography style={{ whiteSpace: 'normal' }}>
                              {filter?.query?.setB?.query}
                            </Typography>
                          }
                          sx={{ marginRight: 2, height: '100%' }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title="Delete Filter"
        content="Are you sure you want to delete the filter?"
        handleSuccess={() => deleteFilters(filter?.filterName)}
        closeLabel="Cancel"
        successLabel="Delete"
        variant="contained"
        color="error"
      />
    </>
  );
};
//
export default Row;
