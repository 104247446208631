import { useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Toolbar,
} from '@mui/material';
import { DateTime } from 'luxon';
import { differenceInDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  selectVisualFilters,
  selectPmsSync,
  selectPaceHorizon,
  selectPaceBookingStyle,
  selectBreakdownDetails,
  selectEnableHotelGroups,
  selectTargetDate,
  selectIsSignedIn,
  selectHotelName,
  selectHotelDropdownList,
  selectHotelGroupDropdownList,
  selectMergedHotelList,
  selectsHotelForecast,
} from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { getSelectedBreakdown, shortcut } from 'modules/dashboard/functions';
import {
  PACE_HORIZON_SELECTION,
  REPORT_TYPE,
  RESET_BOOKING_DATE,
  SHORTCUT_KEY,
  TAB_VALUE,
  TAGS,
} from 'modules/dashboard/constants';
import { CustomStack } from 'modules/common/components';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import ActionButton from 'modules/common/components/action-button';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import PmsToggle from 'modules/dashboard/components/tab-container/inner-filter/components/pms-toggle';
import LagAlignedWindow from 'modules/dashboard/components/tab-container/inner-filter/components/lag-aligned-window';
import BreakdownWindow from 'modules/dashboard/components/tab-container/inner-filter/components/breakdown-window';
import {
  getDateComparisonFilter,
  getFocusOnFilter,
  getPercentageList,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
  getSubPaceFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { ENVIRONMENT } from 'config';
import { isEmpty } from 'modules/common/helpers/object';
import {
  selectActivePaceTab,
  selectEnableSecondaryHotelGroups,
  selectHotelFocusOn,
  selectIsForecast,
  selectIsPaceDetailed,
  selectIsPaceForecastDisabled,
  selectIsPrimary,
  selectNormalizedView,
  selectPaceAlignment,
  selectPaceAlignmentToggle,
  selectPaceBookingDate,
  selectPaceBookingPaceWindow,
  selectPaceChartBreakdown,
  selectPaceCumulativeView,
  selectPaceDateComparison,
  selectPaceFocusOn,
  selectPaceLagAligned,
  selectPaceSymmetricComparisonToggle,
  selectPercentage,
  selectSymmetricComparisonIsDisplay,
} from '../../selectors';
import { paceActions } from '../../slice';
import {
  BookingDatePicker,
  CompareHotelsWindow,
  PercentageWindow,
  VsComparisonWindow,
} from '../../../inner-filter';
import usePaceSegmentCommandFlow from '../../hooks/use-pace-segment-command-flow';
import usePaceTimeCommandFlow from '../../hooks/use-pace-time-command-flow';
import usePaceHotelCommandFlow from '../../hooks/use-pace-hotel-command-flow';
import { selectOrganization } from '../../../../../../common/auth/selectors';

/**
 * Filters for pace widget include PMS Sync, Pace Horizon, pace variable and pace forecast
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const PaceFilter = ({
  tabValue,
  latestDate,
  hotelTimezone,
  toggleDisabled,
  reportType,
  visual,
  visualName,
  isLoaded,
}) => {
  const dispatch = useDispatch();

  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const mergedHotelList = useSelector(selectMergedHotelList);
  const customBooking = useSelector(selectPaceBookingStyle);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const breakdownData = useSelector(selectBreakdownDetails);
  const isDetailed = useSelector(selectIsPaceDetailed);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const alignment = useSelector(selectPaceAlignment);
  const isPaceForecast = useSelector(selectIsForecast);
  const hotelList = useSelector(selectHotelDropdownList);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const normalizedViewToggle = useSelector(selectNormalizedView);
  const bookingDate = useSelector(selectPaceBookingDate);
  const isForecastDisabled = useSelector(selectIsPaceForecastDisabled);
  const activeTab = useSelector(selectActivePaceTab);
  const isHotelForecast = useSelector(selectsHotelForecast);
  const organization = useSelector(selectOrganization);

  // Get persisted values for hotel name and stay date
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const targetDate = useSelector(selectTargetDate);
  const isSignIn = useSelector(selectIsSignedIn);
  const percentage = useSelector(selectPercentage);
  const paceAlignmentToggle = useSelector(selectPaceAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const dateComparison = useSelector(selectPaceDateComparison);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const isPrimary = useSelector(selectIsPrimary);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);
  //
  const [paceList, setPaceList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [percentageList, setPercentage] = useState([]);
  const [isPercentageDisabled, setIsPercentageDisabled] = useState();
  const [dateComparisonList, setDateComparisonList] = useState([]);
  // Triggered on visualFilters, chartBreakdown or isDetails toggle change
  useEffect(() => {
    // Assign values to selected breakdown
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);

  useEffect(() => {
    // To off forecast when focus on value is selected
    if (!isEmpty(focusOn)) dispatch(paceActions.setIsPaceForecast(false));
  }, [focusOn]);

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(paceActions.setNormalizedView(false));
    dispatch(paceActions.setIsPrimary(false));
    dispatch(paceActions.setPaceAlignmentToggle(false));
    dispatch(paceActions.setPaceSymmetricComparisonToggle(true));
    dispatch(
      paceActions.setPaceDateComparison(
        dateComparisonList?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
    dispatch(
      dashboardActions.setPaceHorizon(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
        )[0]?.id
      )
    );
    dispatch(dashboardActions.setPmsSync(false));
    dispatch(paceActions.setPaceFocusOn([]));
    dispatch(
      paceActions.setPaceChartBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0].id
      )
    );
    dispatch(paceActions.setIsPaceDetailed(false));
    dispatch(paceActions.setPaceLagAligned(false));
    dispatch(paceActions.setPaceCumulativeView(true));
    dispatch(paceActions.setIsPaceForecast(false));
    dispatch(paceActions.setPaceBookingPaceWindow(PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON));
    dispatch(paceActions.setPaceBookingDate(RESET_BOOKING_DATE));
    dispatch(
      paceActions.setIsForecastDisabled(
        (!organization?.enableForecastFilter || !isHotelForecast) ?? false
      )
    );
  };

  // Triggered when visualFilter value changes
  useEffect(() => {
    // Update pace variable filter list
    const subPaceFilter = getSubPaceFilter(visualFilters);
    if (subPaceFilter) {
      setPaceList(subPaceFilter);
    }
    // Set chart breakdown list
    const subChartBreakdownList = getSubBreakdownFilter(visualFilters);
    if (subChartBreakdownList) {
      setChartBreakdownList(subChartBreakdownList);
    }
    // Set percentage list
    const percentageItemList = getPercentageList(visualFilters);
    if (percentageList) {
      setPercentage(percentageItemList);
    }
  }, [visualFilters]);

  // Shortcut function to set pms sync value
  shortcut(tabValue, TAB_VALUE.PACE_WIDGET, SHORTCUT_KEY.PMS_TOGGLE, () =>
    dispatch(dashboardActions.setPmsSync(!pmsSyncToggle))
  );
  // Triggered on change of chartBreakdown, visualFilters, breakdownData or selectedBreakdown
  useEffect(() => {
    // Set focus on list relevant for selected chart breakdown
    if (selectedBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [visualFilters, breakdownData, selectedBreakdown]);
  //
  const focusOnLabel = focusOn?.length ? 'Focused on' : 'Select Focus on';
  /**
   * set pace widget pace horizon value
   * @param {*} event - on change event
   */
  const handlePaceHorizonChange = (event) => {
    dispatch(dashboardActions.setPaceHorizon(event.target.value));
  };
  /**
   * set breakdown value
   * @param {*} event - onChanged event
   */
  const handleBreakdownChange = (event) => {
    dispatch(paceActions.setPaceChartBreakdown(event.target.value));
    dispatch(paceActions.setPaceFocusOn([]));
  };
  /**
   * set isDetailed value
   * @param {*} event - onChanged event
   */
  const handleIsDetailedChange = (event) => {
    dispatch(paceActions.setIsPaceDetailed(event.target.checked));
    dispatch(paceActions.setPaceFocusOn([]));
  };
  /**
   * set focus on value
   * @param {*} event - on changed event
   * @param {*} value - selected value
   */
  const handleFocusOnChange = (event, value) => {
    dispatch(paceActions.setPaceFocusOn(value));
  };
  // reset focus on drop down when hotel groups option is selected
  useEffect(() => {
    dispatch(paceActions.setPaceFocusOn([]));
  }, [enableHotelGroups]);
  // Alignment toggle change handler
  const handleToggleChange = (event) => {
    dispatch(paceActions.setPaceAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    dispatch(
      paceActions.setPaceDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };
  /**
   * set percentage value
   * @param {*} event - on changed event
   */
  const handlePercentageChange = (event) => {
    dispatch(paceActions.setPercentage(event.target.value));
  };
  // Triggered when targetDate, latestDate, paceCumulative value change
  useEffect(() => {
    // Set state to enable/ disable percentage filter radio group
    const lastBookingDateUtc = latestDate ? DateTime.fromSQL(latestDate, { zone: 'utc' }) : '';
    const formattedLastBookingDate = latestDate
      ? lastBookingDateUtc.setZone(hotelTimezone).toFormat('MM-dd-yyyy')
      : '';
    if (paceCumulative && formattedLastBookingDate && !isPrimary) {
      setIsPercentageDisabled(
        !(differenceInDays(new Date(targetDate.endDate), new Date(formattedLastBookingDate)) <= 0)
      );
    } else {
      setIsPercentageDisabled(true);
    }
  }, [targetDate, latestDate, paceCumulative, isPrimary]);
  /**
   * set symmetric comparison value
   * @param {*} event - on change event
   */
  const handleSymmetricComparisonChange = (event) => {
    dispatch(paceActions.setPaceSymmetricComparisonToggle(event.target.checked));
  };
  /**
   * set comparison window value
   * @param {*} event - on changed event
   */
  const handleComparisonChange = (event) => {
    dispatch(paceActions.setPaceDateComparison(event.target.value));
  };
  // Triggered when alignment, visualFilters, alignmentToggle changes
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        paceActions.setPaceDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, paceAlignmentToggle]);
  // Triggered when alignmentToggle, visualFilters values change
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, paceAlignmentToggle);
    dispatch(
      paceActions.setPaceAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [paceAlignmentToggle, visualFilters]);
  useEffect(() => {
    if (paceLagAligned) {
      dispatch(paceActions.setPaceBookingPaceWindow(PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON));
    }
  }, [paceLagAligned]);
  //
  useEffect(() => {
    if (bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON) {
      dispatch(
        paceActions.setPaceBookingDate({
          ...bookingDate,
          endDate: targetDate.endDate,
        })
      );
    }
  }, [bookingPaceWindow, targetDate]);
  //
  usePaceSegmentCommandFlow(isLoaded, chartBreakdownList, focusOnList);
  //
  usePaceHotelCommandFlow(isLoaded, hotelList, hotelGroupList);
  //
  usePaceTimeCommandFlow(
    isLoaded,
    paceList,
    percentageList,
    visualFilters,
    customBooking,
    dateComparison,
    handleComparisonChange,
    handleToggleChange
  );
  //
  useEffect(() => {
    // Assign forecast disabled state on breakdown focus on change and active tab change
    if (activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET || !isEmpty(focusOn)) {
      dispatch(paceActions.setIsForecastDisabled(true));
      dispatch(paceActions.setIsPaceForecast(false));
    } else {
      dispatch(
        paceActions.setIsForecastDisabled(
          (!organization?.enableForecastFilter || !isHotelForecast) ?? false
        )
      );
    }
  }, [activeTab, focusOn, isHotelForecast]);
  //
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
        id="pace-pms-toggle"
      />{' '}
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <FormLabel>Booking Window</FormLabel>
          <RadioGroup
            value={bookingPaceWindow ?? PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON}
            name="radio-buttons-group"
            onChange={(event) => {
              dispatch(paceActions.setPaceBookingPaceWindow(event.target.value));
              dispatch(paceActions.setPaceBookingDate(RESET_BOOKING_DATE));
            }}
          >
            <FormControlLabel
              id="auto-toggle-booking-pace-window"
              sx={{
                my: -1,
              }}
              value={PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW}
              control={<Radio />}
              label="Booking Pace Window"
              disabled={paceLagAligned}
            />
            {bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW && (
              <BookingDatePicker
                id="auto-pace-booking-date-picker"
                reportType={reportType}
                latestDate={latestDate}
              />
            )}
            <FormControlLabel
              id="auto-toggle-booking-pace-horizon"
              sx={{
                my: -1,
              }}
              value={PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON}
              control={<Radio />}
              label="Booking Pace Horizon"
            />

            <RadioGroup value={paceHorizon ?? null} onChange={handlePaceHorizonChange}>
              {paceList
                ?.sort(
                  (element1, element2) =>
                    element1.label?.[0] &&
                    element2.label?.[0] &&
                    Number(element1?.label?.[0]?.split(' ')[0]) -
                      Number(element2?.label?.[0]?.split(' ')[0])
                )
                ?.map((item) => (
                  <FormControlLabel
                    id={`${item?.label?.[0]?.split(" ")[0]}-pace-horizon`}
                    key={item?.id}
                    value={item?.id}
                    control={<Radio />}
                    label={item?.label}
                    disabled={bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_WINDOW}
                    sx={{
                      ml: 2,
                      my: -1,
                    }}
                  />
                ))}
            </RadioGroup>
          </RadioGroup>
        </FormControl>
      </CustomStack>
      <br />
      <LagAlignedWindow
        paceLagAligned={paceLagAligned}
        paceCumulative={paceCumulative}
        changePaceLagAligned={paceActions.setPaceLagAligned}
        changePaceCumulative={paceActions.setPaceCumulativeView}
        isPaceForecast={isPaceForecast}
        IsForecastDisabled={isForecastDisabled}
        setIsPaceForecast={paceActions.setIsPaceForecast}
      />
      <br />
      <BreakdownWindow
        id="pace"
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={handleIsDetailedChange}
        handleBreakdownChange={handleBreakdownChange}
        isFocusOnAvailable
        focusOnList={focusOnList}
        focusOn={focusOn}
        focusOnLabel={focusOnLabel}
        handleFocusOnChange={handleFocusOnChange}
      />
      <br />
      <CompareHotelsWindow
        selectedHotelName={selectHotelName}
        hotelList={hotelList ?? []}
        mergedHotelList={mergedHotelList ?? []}
        focusOn={hotelFocusOn}
        focusOnLabel={hotelFocusOn?.length ? 'Compared with' : 'Select Hotel'}
        handleCompareHotelsChange={(event, value) => {
          dispatch(paceActions.setHotelFocusOn(value));
        }}
        normalizedViewToggle={normalizedViewToggle}
        handleTrendHotelNormalize={(event) =>
          dispatch(paceActions.setNormalizedView(event.target.checked))
        }
        enableSecondaryHotelGroups={enableSecondaryHotelGroups}
        hotelGroupList={hotelGroupList}
      />
      <br />
      <VsComparisonWindow
        id="pace"
        customBooking={customBooking}
        alignmentToggle={paceAlignmentToggle}
        handleToggleChange={handleToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleComparisonChange}
        handleSymmetricComparisonChange={handleSymmetricComparisonChange}
        isCustomOptionAvailable={false}
        hideOTBAndCustom
        reportType={reportType}
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() => {
          dispatch(paceActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay));
        }}
        isEnableBreakdownCheckbox
        handlePrimaryChange={(event) => dispatch(paceActions.setIsPrimary(event.target.checked))}
        isPrimary={isPrimary}
      />
      <br />
      <PercentageWindow
        percentage={percentage}
        percentageList={percentageList}
        handlePercentageChange={handlePercentageChange}
        isPercentageDisabled={isPercentageDisabled}
      />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default PaceFilter;
