import EventEmitter from './utils/emitter';
import events from './utils/events';

export { default as events } from './utils/events';
//
export {
  TABS as tabs,
  compareWindows,
  commands,
  consoleCommands,
  paceVariableNames,
  breakdownList,
  encodedTabs,
  decodedTabs,
  EXECUTION_TYPE,
  TABULAR_VIEWS,
  timeFrameOptions,
  COLLAPSE_VISUALS,
} from './utils/constants';
//
export const UiController = {
  /**
   * Get inputs and event to execute the emitter dispatch function
   * @param {String} event - event name
   * @param {Object} inputs - passed parameter values for lister function
   * @returns
   */
  setInput: (event, inputs) => {
    EventEmitter.dispatch(event, inputs);
  },
  /**
   * Add event listener to given event
   * @param {String} event - ex: events.STAY_DATE - event name
   * @param fn - listener function
   * @returns
   */
  subscribe: (event, fn) => {
    EventEmitter.subscribe(event, fn);
  },
  /**
   * Remove event listener to given event
   * @param {String} event - event name
   * @param fn - listener function
   * @returns
   */
  unsubscribe: (event, fn) => {
    EventEmitter.unsubscribe(event, fn);
  },
  /**
   * Add event listener to error event
   * @param {String} event - event name
   * @param error - error listener function
   * @returns
   */
  onError: (error) => {
    EventEmitter.subscribe(events.ERROR, (err) => console.log(err));
    EventEmitter.dispatch(events.ERROR, error);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set hotel name
   * @param
   * @param {Object} input - ex:nomosoho
   * @returns
   */
  setHotelName: (input) => {
    EventEmitter.dispatch(events.SET_HOTEL, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set hotel group name
   * @param
   * @param {Object} input - ex:nomosoho
   * @returns
   */
  setHotelGroupName: (input) => {
    EventEmitter.dispatch(events.SET_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function set pms sync state
   * @param
   * @param {Object} input - ex:0 ||1
   * @returns
   */
  setPmsSync: (input) => {
    EventEmitter.dispatch(events.SET_PMS_SYNC, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function set weekly view
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setWeeklyView: (input) => {
    EventEmitter.dispatch(events.WEEKLY_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to date range
   * @param
   * @param {Object} input ex: 20230301-20230415 || 20230401 || lw || mtd
   * @returns
   */
  setDateRange: (input) => {
    EventEmitter.dispatch(events.STAY_DATE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tab
   * @param
   * @param {Object} input ex:sm || pa || rm || tt || th
   * @returns
   */
  setTab: (input) => {
    EventEmitter.dispatch(events.SET_TAB, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking date range
   * @param
   * @param {Object} input  ex: { selection:  startDate : new Date(), endDate: new Date(), key: "selection"}
   * @returns
   */
  setBookingDateRange: (input) => {
    EventEmitter.dispatch(events.BOOKING_DATE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass booking date range
   * @param
   * @param {Object} input ex: 20230301-20230415 || 20230401 || lw || mtd
   * @returns
   */
  setBookingDate: (input) => {
    EventEmitter.dispatch(events.SET_BOOKING_DATE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in summary tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setSummaryAlignment: (input) => {
    EventEmitter.dispatch(events.SUMMARY_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in workbook tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setWorkbookAlignment: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in trend time tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setTrendTimeAlignment: (input) => {
    EventEmitter.dispatch(events.TREND_TIME_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in web click tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setWebClickAlignment: (input) => {
    EventEmitter.dispatch(events.WEB_CLICK_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in i prefer tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setIPreferAlignment: (input) => {
    EventEmitter.dispatch(events.IPREFER_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in pace time tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setPaceTimeAlignment: (input) => {
    EventEmitter.dispatch(events.PACE_TIME_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in pace time tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setPaceAlignment: (input) => {
    EventEmitter.dispatch(events.PACE_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set alignment in tabular tab
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setTabularAlignment: (input) => {
    EventEmitter.dispatch(events.TABULAR_ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass alignment value
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setAlignment: (input) => {
    EventEmitter.dispatch(events.ALIGNMENT, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass symmetric comparison value
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setSymmetricComparison: (input) => {
    EventEmitter.dispatch(events.SYMMETRIC_COMPARISON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking dates in summary tab
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setSummaryCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking dates in workbook tab
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setWorkbookCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking dates in trend tabs
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setTrendCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_TREND_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking dates in web click tab
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setWebClickCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in i-prefer tab
   * @param
   * @param {Object} input ex: custom date key and date
   * @returns
   */
  setWebIPreferCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in pace tab
   * @param
   * @param {Object} input ex: custom date key and date
   * @returns
   */
  setPaceCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_PACE_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in tabular tab
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setTabularCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking dates in regional map tab
   * @param
   * @param {Object} input ex: otb || 20230301-20230415
   * @returns
   */
  setRelationalMapCustomBooking: (input) => {
    EventEmitter.dispatch(events.SET_RELATION_MAP_CUSTOM_BOOKING, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass cumulative view value
   * @param
   * @param {Object} input ex:0 ||1
   * @returns
   */
  setCumulativeView: (input) => {
    EventEmitter.dispatch(events.SET_CUMULATIVE_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in summary tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setSummaryCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in workbook tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setWorkbookCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in trend time tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setTrendTimeCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_TREND_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in web click tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setWebClickCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in i prefer tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setIPreferCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in pace time tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setPaceTimeCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in pace tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setPaceCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_PACE_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare window value in tabular tab
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setTabularCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass compare window value
   * @param
   * @param {Object} input ex: otb | pw | pm | py
   * @returns
   */
  setCompareWIndow: (input) => {
    EventEmitter.dispatch(events.SET_COMPARE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in summary tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setSummaryPaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in pace time widget tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setPaceTimePaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in pace segment widget tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setPaceSegmentPaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_PACE_SEGMENT_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in 3d plot tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setPlotPaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_PLOT_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in web click tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setWebClickPaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace horizon value in pace tab
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setPacePaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_PACE_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass pace horizon value
   * @param
   * @param {Object} input ex: 14 | 28 | 90 | 180
   * @returns
   */
  setPaceHorizon: (input) => {
    EventEmitter.dispatch(events.SET_PACE_HORIZON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace variable value in summary tab
   * @param
   * @param {Object} input ex: rms | rev | adr
   * @returns
   */
  setSummaryPaceVariable: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_PACE_VARIABLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace variable value in pace time widget tab
   * @param
   * @param {Object} input ex: rms | rev | adr
   * @returns
   */
  setPaceTimePaceVariable: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_PACE_VARIABLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace variable value in 3d plot tab
   * @param
   * @param {Object} input ex: rms | rev | adr
   * @returns
   */
  setPlotPaceVariable: (input) => {
    EventEmitter.dispatch(events.SET_PLOT_PACE_VARIABLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass pace variable value
   * @param
   * @param {Object} input ex: rms | rev | adr
   * @returns
   */
  setPaceVariable: (input) => {
    EventEmitter.dispatch(events.SET_PACE_VARIABLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass breakdown value
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in summary tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setSummaryBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in reginal map tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setReginalMapBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_REGINAL_MAP_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in trend segment tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setTrendSegmentBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_SEGMENT_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in i prefer tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setIPreferBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in pace segment tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setPaceSegmentBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_PACE_SEGMENT_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in pace tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setPaceBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_PACE_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown value in tabular tab
   * @param
   * @param {Object} input ex: ch0 | ch1 | ms0 | ms1 | rc0 | rc1 | rg0 | rg1
   * @returns
   */
  setTabularBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass compare hotels value
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare hotels in trend hotels tab
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setTrendHotelCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_TREND_HOTELS_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare hotels in summary hotels tab
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setSummaryHotelCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare hotels in workbook hotels tab
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setWorkbookHotelCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare hotels in web clicks tab
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setWebClickCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set compare hotels in pace tab
   * @param
   * @param {Object} input ex: nomosoho
   * @returns
   */
  setPaceCompareHotels: (input) => {
    EventEmitter.dispatch(events.SET_PACE_COMPARE_HOTELS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass focus on value
   * @param
   * @param {Object} input ex: be || cc || gds || vo || prs || ids
   * @returns
   */
  setFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in reginal map tab
   * @param
   * @param {Object} input ex: be || cc || gds || vo || prs || ids
   * @returns
   */
  setRegionalMapFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_REGIONAL_MAP_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in trend segment tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setTrendSegmentFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_TREND_SEGMENT_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in web click tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setWebClickFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in i prefer tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setIPreferFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in pace segment tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setPaceSegmentFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_PACE_SEGMENT_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in pace tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setPaceFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_PACE_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set focus on value in tabular tab
   * @param
   * @param {Object} input ex: focus on list option names
   * @returns
   */
  setTabularFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set stay forward period
   * @param
   * @param {Object} input ex: number
   * @returns
   */
  setStayForwardPeriod: (input) => {
    EventEmitter.dispatch(events.SET_NEXT_DAY, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set stay forward month
   * @param
   * @param {Object} input ex: number
   * @returns
   */
  setStayForwardMonth: (input) => {
    EventEmitter.dispatch(events.SET_NEXT_MONTH, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set stay backward period
   * @param
   * @param {Object} input ex: number
   * @returns
   */
  setStayBackwardPeriod: (input) => {
    EventEmitter.dispatch(events.SET_PREVIOUS_DAY, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set stay backward month
   * @param
   * @param {Object} input ex: number
   * @returns
   */
  setStayBackwardMonth: (input) => {
    EventEmitter.dispatch(events.SET_PREVIOUS_MONTH, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass normalized view value
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set normalized view in trend hotels tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTrendHotelNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_TREND_HOTEL_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set normalized view in summary tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSummaryNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set normalized view in workbook tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWorkbookNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set normalized view in web click
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWebClickNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set normalized view in pace
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceNormalizedView: (input) => {
    EventEmitter.dispatch(events.SET_PACE_NORMALIZED_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set steps
   * @param
   * @param {Object} input ex: steps names
   * @returns
   */
  setSteps: (input) => {
    EventEmitter.dispatch(events.SET_STEPS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set  scale factor
   * @param
   * @param {Object} input ex: 0-100
   * @returns
   */
  setScaleFactor: (input) => {
    EventEmitter.dispatch(events.SET_SCALE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click steps
   * @param
   * @param {Object} input ex: steps names
   * @returns
   */
  setWebClickSteps: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_STEPS, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click scale factor
   * @param
   * @param {Object} input ex: 0-100
   * @returns
   */
  setWebClickScaleFactor: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_SCALE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in summary tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setSummaryCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in workbook tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setWorkbookCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in trend time tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setTrendTimeCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_TREND_TIME_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in web click tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setWebClickCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in i prefer tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setIPreferCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set custom booking value in i prefer tab
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setTabularCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function set custom date range
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setCustomDateRange: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_DATE_RANGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function pass custom date range value
   * @param
   * @param {Object} input ex: custom date key and  date
   * @returns
   */
  setCustomDateRangeValue: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_DATE_RANGE_VALUE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set aggregation A value
   * @param
   * @param {Object} input ex: setA options
   * @returns
   */
  setCustomAggregationA: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_AGGREGATION_SET_A, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set aggregation B value
   * @param
   * @param {Object} input ex: setB options
   * @returns
   */
  setCustomAggregationB: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_AGGREGATION_SET_B, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function pass custom aggregation values
   * @param
   * @param {Object} input ex: group A and group B options
   * @returns
   */
  setCustomAggregation: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_AGGREGATION, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass tabular view values
   * @param
   * @param {Object} input ex: 1,2,3...
   * @returns
   */
  setTabularView: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass tabular view values
   * @param
   * @param {Object} input ex: rev, adr, tra ...
   * @returns
   */
  setMetricSelection: (input) => {
    EventEmitter.dispatch(events.SET_METRIC_SELECTION, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass tabular view values
   * @param
   * @param {Object} input ex: rev, adr, tra ...
   * @returns
   */
  setIPreferMetricSelection: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_METRIC_SELECTION, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass tabular view values
   * @param
   * @param {Object} input ex: 1,2,3...
   * @returns
   */
  setTimeFrame: (input) => {
    EventEmitter.dispatch(events.SET_TIME_FRAME, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass tabular view values
   * @param
   * @param {Object} input ex: 1,2,3...
   * @returns
   */
  setIPreferTimeFrame: (input) => {
    EventEmitter.dispatch(events.SET_IPREFER_TIME_FRAME, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass lag aligned value
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setLagAligned: (input) => {
    EventEmitter.dispatch(events.SET_LAG_ALIGNED, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set lag aligned value in pace time tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceTimeLagAligned: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_LAG_ALIGNED, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set lag aligned value pace segment tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceSegmentLagAligned: (input) => {
    EventEmitter.dispatch(events.SET_PACE_SEGMENT_LAG_ALIGNED, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set lag aligned value pace tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceLagAligned: (input) => {
    EventEmitter.dispatch(events.SET_PACE_LAG_ALIGNED, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass booking forecast values
   * @param
   * @param {Object} input ex: dates list
   * @returns
   */
  setBookingForecast: (input) => {
    EventEmitter.dispatch(events.SET_BOOKING_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace booking forecast values
   * @param
   * @param {Object} input ex: dates list
   * @returns
   */
  setPaceTimeBookingForecast: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_BOOKING_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass percentage value
   * @param
   * @param {Object} input ex: 25,50,75...
   * @returns
   */
  setPercentage: (input) => {
    EventEmitter.dispatch(events.SET_PERCENTAGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set percentage value in pace time tab
   * @param
   * @param {Object} input ex: 25,50,75...
   * @returns
   */
  setPaceTimePercentage: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TIME_PERCENTAGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set percentage value in pace time tab
   * @param
   * @param {Object} input ex: 25,50,75...
   * @returns
   */
  setPacePercentage: (input) => {
    EventEmitter.dispatch(events.SET_PACE_PERCENTAGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular views in trend tab
   * @param
   * @param {Object} input ex: A0,B1..
   * @returns
   */
  setTrendTimeTabularView: (input) => {
    EventEmitter.dispatch(events.SET_TREND_TIME_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular views in pace tab
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceTabularView: (input) => {
    EventEmitter.dispatch(events.SET_PACE_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular views in trend segment tab
   * @param
   * @param {Object} input ex: A0,B1..
   * @returns
   */
  setTrendSegmentTabularView: (input) => {
    EventEmitter.dispatch(events.SET_TREND_SEGMENT_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular views in trend hotels tab
   * @param
   * @param {Object} input ex: A0,B1..
   * @returns
   */
  setTrendHotelsTabularView: (input) => {
    EventEmitter.dispatch(events.SET_TREND_HOTEL_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular views in regional map tab
   * @param
   * @param {Object} input ex: A0,B1..
   * @returns
   */
  setRegionalMapTabularView: (input) => {
    EventEmitter.dispatch(events.SET_REGIONAL_MAP_TABULAR_VIEW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function pass custom aggregation A values
   * @param
   * @param {Object} input ex: setA
   * @returns
   */
  setCustomAggregationAValue: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_AGGREGATION_A_VALUE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function pass custom aggregation B values
   * @param
   * @param {Object} input ex: setB
   * @returns
   */
  setCustomAggregationBValue: (input) => {
    EventEmitter.dispatch(events.SET_CUSTOM_AGGREGATION_B_VALUE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass extending forecast value
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setExtendingForecast: (input) => {
    EventEmitter.dispatch(events.SET_EXTENDING_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass extending forecast value
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSummaryExtendingForecast: (input) => {
    EventEmitter.dispatch(events.SET_EXTENDING_SUMMARY_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set summary symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSummarySymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTrendSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_TREND_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_PACE_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWebClickSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTabularSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set workbook symmetric comparison collapse
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWorkbookSymmetricComparisonCollapse: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_SYMMETRIC_COMPARISON_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to pass visual collapse states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set summary visual collaspe states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSummaryVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set summary visual collaspe states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTrendVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_TREND_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set workbook visual collapse states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWorkbookVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace visual collapse states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_PACE_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click visual collapse states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setWebClickVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set tabular visual collapse states
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTabularVisualCollapse: (input) => {
    EventEmitter.dispatch(events.SET_TABULAR_VISUAL_COLLAPSE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set forecast toggle
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setForecastToggle: (input) => {
    EventEmitter.dispatch(events.SET_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend forecast toggle
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setTrendForecastToggle: (input) => {
    EventEmitter.dispatch(events.SET_TREND_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace forecast toggle
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setPaceForecastToggle: (input) => {
    EventEmitter.dispatch(events.SET_PACE_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set summary forecast toggle
   * @param
   * @param {Object} input ex: 0 || 1
   * @returns
   */
  setSummaryForecastToggle: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_FORECAST, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set secondary hotel group toggle and values
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setSecondaryHotelGroup: (input) => {
    EventEmitter.dispatch(events.SET_SECONDARY_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend hotel groups
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setTrendHotelGroups: (input) => {
    EventEmitter.dispatch(events.SET_TREND_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set webclick hotel groups
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setWebClickHotelGroups: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace hotel groups
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setPaceHotelGroups: (input) => {
    EventEmitter.dispatch(events.SET_PACE_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set summary hotel groups
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setSummaryHotelGroups: (input) => {
    EventEmitter.dispatch(events.SET_SUMMARY_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set workbook hotel groups
   * @param
   * @param {Object} input ex: test1
   * @returns
   */
  setWorkbookHotelGroups: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_HOTEL_GROUP, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set active trend tab
   * @param
   * @param {Object} input ex: time
   * @returns
   */
  setActiveTab: (input) => {
    EventEmitter.dispatch(events.SET_ACTIVE_TAB, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend active tab
   * @param
   * @param {Object} input ex: time
   * @returns
   */
  setTrendActiveTab: (input) => {
    EventEmitter.dispatch(events.SET_TREND_ACTIVE_TAB, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace active tab
   * @param
   * @param {Object} input ex: time
   * @returns
   */
  setPaceActiveTab: (input) => {
    EventEmitter.dispatch(events.SET_PACE_ACTIVE_TAB, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set breakdown toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setBreakdownToggle: (input) => {
    EventEmitter.dispatch(events.SET_BREAKDOWN_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend breakdown toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setTrendBreakdownToggle: (input) => {
    EventEmitter.dispatch(events.SET_TREND_BREAKDOWN_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace breakdown toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setPaceBreakdownToggle: (input) => {
    EventEmitter.dispatch(events.SET_PACE_BREAKDOWN_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set percentage toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setPercentageChangeToggle: (input) => {
    EventEmitter.dispatch(events.SET_PERCENTAGE_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend percentage toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setTrendPercentageChangeToggle: (input) => {
    EventEmitter.dispatch(events.SET_TREND_PERCENTAGE_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set segment breakdown toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setSegmentBreakdownToggle: (input) => {
    EventEmitter.dispatch(events.SET_SEGMENT_BREAKDOWN_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set trend segment breakdown toggle
   * @param
   * @param {Object} input ex: 0 | 1
   * @returns
   */
  setTrendSegmentBreakdownToggle: (input) => {
    EventEmitter.dispatch(events.SET_TREND_SEGMENT_BREAKDOWN_TOGGLE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set utm breakdown value
   * @param
   * @param {Object} input ex: abc | xxx
   * @returns
   */
  setUTMBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_UTM_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set utm focus on value
   * @param
   * @param {Object} input ex: abc || xxx
   * @returns
   */
  setUTMFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_UTM_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click utm breakdown value
   * @param
   * @param {Object} input ex: abc | xxx
   * @returns
   */
  setWebCLickUTMBreakdown: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_UTM_BREAKDOWN, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set web click utm focus on value
   * @param
   * @param {Object} input ex: abc || xxx
   * @returns
   */
  setWebCLickUTMFocusOn: (input) => {
    EventEmitter.dispatch(events.SET_WEB_CLICK_UTM_FOCUS_ON, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set booking pace value
   * @param
   * @param {Object} input ex: bpw || bph
   * @returns
   */
  setBookingPaceWindow: (input) => {
    EventEmitter.dispatch(events.SET_BOOKING_PACE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace booking pace radio toggle
   * @param
   * @param {Object} input ex: bpw || bph
   * @returns
   */
  setPaceBookingPaceWindow: (input) => {
    EventEmitter.dispatch(events.SET_PACE_BOOKING_PACE_WINDOW, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set moving average value
   * @param
   * @param {Object} input ex: 17 || 130
   * @returns
   */
  setMovingAverage: (input) => {
    EventEmitter.dispatch(events.SET_MOVING_AVERAGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set pace booking pace radio toggle
   * @param
   * @param {Object} input ex: 17 || 130
   * @returns
   */
  setTrendMovingAverage: (input) => {
    EventEmitter.dispatch(events.SET_TREND_MOVING_AVERAGE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set currency value
   * @param
   * @param {Object} input ex: usd || cad
   * @returns
   */
  setCurrency: (input) => {
    EventEmitter.dispatch(events.SET_CURRENCY, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set currency dropdown
   * @param
   * @param {Object} input ex: usd || cad
   * @returns
   */
  setLocalCurrency: (input) => {
    EventEmitter.dispatch(events.SET_LOCAL_CURRENCY, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set date type value
   * @param
   * @param {Object} input ex: stay || arrival
   * @returns
   */
  setDateType: (input) => {
    EventEmitter.dispatch(events.SET_DATE_TYPE, input);
  },
  /**
   * Get inputs and execute the emitter dispatch function to set workbook date type dropdown
   * @param
   * @param {Object} input ex: stay || arrival
   * @returns
   */
  setWorkbookDateType: (input) => {
    EventEmitter.dispatch(events.SET_WORKBOOK_DATE_TYPE, input);
  },
};
